import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { Context } from 'store/createContext';
import Button from '../button';

const ZoomLink = () => {
  const store = useContext(Context);
  const [link, setLink] = useState(null);

  useEffect(() => {
    getZoomLink();
  }, []);

  const getZoomLink = async () => {
    await axios
      .get(process.env.GATSBY_PROTAG_ADMIN_API + '/zoom-link/' + store.rsvp)
      .then(function(response) {
        if (response.data) {
          setLink(response.data.zoom_link);
          store.setVerified('verified');
          store.setType(response.data.type);
        }
      })
      .catch(function(error) {
        // handle error
        store.setVerified('fail');
      })
      .then(function() {
        // always executed
      });
  };

  const goToZoomLink = () => {
    link && window.open(link);
  };

  if (!store.rsvp || !link || store.eventTiming !== 'currently') {
    return null;
  }

  return (
    <Button red={true} onClick={goToZoomLink}>
      Join Coaching Room
    </Button>
  );
};

export default ZoomLink;
