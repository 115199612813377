import React from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';
import { Link } from 'gatsby';
import posed from 'react-pose';
import styled from 'styled-components';
import { Container } from './header.css';
import Title from 'components/title';
import Nav from 'components/header/nav';
import MEDIA from 'helpers/mediaTemplates';
import { primaryText } from 'constants/theme';

// Example of a component-specific page transition
const AnimatedContainer = posed.div({
  enter: {
    y: 0,
    transition: {
      ease: 'easeInOut',
    },
  },
  exit: {
    y: '-100%',
    transition: {
      ease: 'easeInOut',
    },
  },
});

const NavContainer = styled(Container)`
  ${MEDIA.PHONE`
     flex-direction: column;
  `};
`;

const FlexWrapper = styled.div`
  display: flex;
  align-items: center;

  .gatsby-image-wrapper {
    width: 80px;
    min-width: 80px;
    margin-right: 2rem;

    ${MEDIA.PHONE`
     margin-bottom: 2rem;
  `};

    picture {
      img {
        object-fit: contain;
      }
    }
  }
`;

const StyledBanner = styled.div`
  width: 100%;
  background: ${primaryText};
  display: flex;
  align-items: center;
  justify-content: center;

  p,
  a {
    color: white;
    padding: 1.5rem;
    margin-bottom: 0;
  }

  a {
    text-decoration: underline;
  }
`;

const TitleWrapper = styled.div``;

//use to be the word 'with' and this made more sense...
const splitTitle = title => {
  let split = title.split('Live');
  return {
    main: split[0],
    subTitle: 'Live' + split[1],
  };
};

const Header = ({ title, icon, bannerMessage = '' }) => (
  <AnimatedContainer>
    {bannerMessage ? (
      <StyledBanner>
        <p dangerouslySetInnerHTML={{ __html: bannerMessage }} />
      </StyledBanner>
    ) : null}
    <NavContainer>
      <Link to="/">
        <FlexWrapper>
          <Img fluid={icon ? icon.childImageSharp.fluid : {}} alt={'logo'} />
          <TitleWrapper>
            <Title as="h1" size="large" className="hide-big-phone">
              StoryBrand
            </Title>
            <Title as="h3" className="hide-big-phone">
              Livestream
            </Title>
          </TitleWrapper>
        </FlexWrapper>
      </Link>
      <Nav />
    </NavContainer>
  </AnimatedContainer>
);

Header.propTypes = {
  title: PropTypes.string.isRequired,
  bannerMessage: PropTypes.string,
};

export default Header;
