import React from 'react';
import PropTypes from 'prop-types';
import { Button } from './button.css';

const _Button = ({ children, red, onClick }) => (
  <Button onClick={onClick} red={red}>
    {children}
  </Button>
);

_Button.propTypes = {
  children: PropTypes.node.isRequired,
  red: PropTypes.bool,
  onClick: PropTypes.func,
};

export default _Button;
